

















import { Component } from "vue-property-decorator";
import { Routes } from "@/types/core";
import { mixins } from "vue-class-component";
import { BaseView } from "@/core/UI/Views/BaseView";
import userRolesMixin from "@/mixins/userRoles";
import CyclesList from "@/components/widgets/cycles/CyclesList.vue";

@Component({
  components: {
    CyclesList,
  },
})
export default class CycleEventsView extends mixins(userRolesMixin, BaseView) {
  public created(): void {
    if (!this.VIEW_EVENT_CYCLE) this.$router.push({ name: Routes.noRights });
  }
}
